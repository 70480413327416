import { defaultEnv } from "./env.default";
import { defaults } from "options-defaults";

const environmentEnv = {
    cobShowcase: `$COB_SHOWCASE`,
    production: true,
    reportBugUrl: `$REPORT_BUG_URL`,
    api: {
        url: `$API_URL`,
    },
    webUrl: `$WEB_URL`,
    oAuth: {
        clientId: `$OAUTH_CLIENT_ID`,
        issuer: `$OAUTH_ISSUER`,
    },
};

window.env = defaults(defaultEnv, environmentEnv);
